import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
// import SEO from '../components/SEO';
// import Call from '../components/Call';
import highlightCode from '../utils/highlight-code';

export default function Post({ data }) {
  useEffect(() => {
    highlightCode();
  });

  const post = data.markdownRemark;
  const tags = post.frontmatter.tags || [];
  return (
    <Layout bodyClass='page-blog'>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
        <div className='row justify-content-start'>
          <div className='col-12 col-md-8'>
            <h1 className='title'>{post.frontmatter.title}</h1>
            <small>{post.frontmatter.date.substring(0, 10)}</small>
            <div
              className='content mt-4'
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            {tags.length > 0 && (
              <div>
                <span>Tags:</span>
                {tags.map((tag) => (
                  <span
                    key={`tag-${tag}`}
                    className='badge badge-pill badge-secondary post-tag'
                  >
                    <Link className='post-tag-link' to={`/blog/tags/${tag}`}>
                      {tag}
                    </Link>
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
      }
    }
  }
`;
